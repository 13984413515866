import React from "react";
import Helmet from "react-helmet";
import { injectIntl } from "react-intl";
import styled from "styled-components";
import SignUpForm from "../components/SignUp";
import Box from "../components/Box";
import Header from "../components/Header";

import { graphql } from "gatsby";

const BackgroundBox = styled(Box)`
  background-color: #4844a3;
  min-height: calc(100vh - 159px);
`;

const SignUpPage = ({ intl, location }) => (
  <BackgroundBox>
    <Helmet title={intl.formatMessage({ id: "globals.signUp" })} />
    <Header
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: "globals.courseOverview" }),
          path: intl.formatMessage({ id: "routes.LANDING" })
        },
        {
          title: intl.formatMessage({ id: "globals.signUp" }),
          path: intl.formatMessage({ id: "routes.SIGN_UP" })
        }
      ]}
    />
    <SignUpForm intl={intl} location={location} />
  </BackgroundBox>
);

export const query = graphql`
  query {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;

export default injectIntl(SignUpPage);
